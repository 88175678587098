
import { defineComponent } from 'vue';
import {
    IonPage,
    IonButton,
    IonToolbar,
    IonIcon,
    IonContent,
    IonRouterOutlet
} from '@ionic/vue';

export default defineComponent({
    name: 'Signup',

    components: {
        IonPage,
        IonButton,
        IonToolbar,
        IonIcon,
        IonContent,
        IonRouterOutlet
    },
    computed: {
        isLoyaltyGuest(): boolean {
            const isGuest = this.$route.path.match('/loyalty/guest*') != undefined;
            return isGuest;
        }
    }
});
